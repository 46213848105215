<template>
  <div id="app">
    <van-nav-bar v-if="this.$router.currentRoute.path !== '/'" :title="title" left-text="返回" left-arrow @click-left="onClickLeft" />
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'App',
  data() {
    return {
      title: ''
    }
  },
  methods: {
    onClickLeft() {
      // this.$router.back(-1);
       
      if (window.history.length <= 1) {
        this.$router.push("/")
      } else {
        this.$router.back(1);
      }
    }
  }
}
</script>
<style>
body {
  background-color: rgb(244, 246, 248);
}
</style>
