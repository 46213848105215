import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export const constantRoutes = [
  {
    path: '/',
    name: 'menu',
    component: () => import('../views/menu/MenuIndex.vue'),
    meta:{title:'服务平台',
     requireAuth:true 
     }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path:'/xunchagonggao',
    name:'xunchagonggao',
    component: () => import('../views/xuncha/LunView.vue'),
  },
  {
    path:'/notice/:id',
    name:'notice',
    component: () => import('../views/xuncha/NoticeView.vue'),
  },
  {
    path:'/noticeInfo/:id',
    name:'noticeInfo',
    component: () => import('../views/xuncha/NoticeInfoView.vue'),
  },
  {
    path:'/goReflect',
    name:'goReflect',
    component: () => import('../views/fanying/GoReflectView.vue'),
  },
  {
    path:'/reflect/:id',
    name:'reflect',
    component: () => import('../views/fanying/ReflectView.vue'),
  },
  {
    path:'/Know',
    name:'Know',
    component: () => import('../views/know/KnowView.vue'),
    meta: {
      title: '反映须知',
    }
  },
  {
    path:'/documentList',
    name:'documentList',
    component: () => import('../views/zhengce/DocumentListView.vue'),
  },
  {
    path:'/document/:id',
    name:'document',
    component: () => import('../views/zhengce/DocumentView.vue'),
  },
]


export default new VueRouter({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

